<template>
	<section class="roleBox" :style="'background-image: url('+roleBg+');'">
		  <div class="roleDetail">
			  <div class="avaBox">
				  <ul>
					  <li class="role1" :class="{'active': activeRole == 1}" @click="handleRole(1)"></li>
					  <li class="role2" :class="{'active': activeRole == 2}" @click="handleRole(2)"></li>
					  <li class="role3" :class="{'active': activeRole == 3}" @click="handleRole(3)"></li>
					  <li class="role4" :class="{'active': activeRole == 4}" @click="handleRole(4)"></li>
					  <li class="role5" :class="{'active': activeRole == 5}" @click="handleRole(5)"></li>
				  </ul>
			  </div>
			  <template v-for="(item,index) in roleList" :key="index">
				  <div class="roleInfo" v-if="index == activeRole -1">
					  <div class="bigSkill">
						  <h4>{{ item.skillTitle }}</h4>
						  <p>{{ item.skillDesc }}</p>
						  <img :src="item.skillImg" />
					  </div>
					  <div class="skillList">
						  <h4>스킬</h4>
						  <ul>
							  <li v-for="(e,i) in item.skills" :key="i">
								  <img :src="e.img" />
								  <span>{{ e.desc }}</span>
							  </li>
						  </ul>
					  </div>
					  <div class="rolePerson">
						  <img :src="item.roleImg" />
					  </div>
				  </div>
			  </template>
		  </div>
	</section>
</template>

<script setup>
	import { ref, reactive, onMounted } from "vue";
	const gameId = ref(816)
	const activeRole = ref(1)
	const roleBg = require('@/assets/images/web/'+gameId.value+'/bg_role.jpg')
	const roleList = ref([
		{
			skillTitle: '궁국기:기문둔갑',
			skillDesc: '타겟 적군을 2회 공격,매회마다 공격력의 113% 피해를 입힘,50%의 확률로 추가 공격 가능,총 2회 발동',
			skills: [
				{ img: require('@/assets/images/web/816/other/skill_101.png'), desc: '감로:1레벨마다 공격력 100 증가' },
				{ img: require('@/assets/images/web/816/other/skill_102.png'), desc: '팔괘:전체 인원 물리 방어력 증가' },
				{ img: require('@/assets/images/web/816/other/skill_103.png'), desc: '통찰:1레벨마다 HP 750 증가' },
				{ img: require('@/assets/images/web/816/other/skill_104.png'), desc: '파진:1레벌마다 물리 방어력 50 증가' },
				{ img: require('@/assets/images/web/816/other/skill_105.png'), desc: '와룡:1레벌마다 마법 방어력 50 증가' }
			],
			skillImg: require('@/assets/images/web/816/other/role1.gif'),
			roleImg: require('@/assets/images/web/816/other/role1.png')
		},
		{
			skillTitle: '궁국기:폐월수화',
			skillDesc: '전체 아군 인원의 HP를 치유,HP 치유량은 자신의 공격의 240% 동시에 전체 아군 인원의 피해 면역 12% 증가',
			skills: [
				{ img: require('@/assets/images/web/816/other/skill_201.png'), desc: '감로:1레벨마다 공격력 100 증가' },
				{ img: require('@/assets/images/web/816/other/skill_202.png'), desc: '고무:전투 진입후 전체 인원 회피율 4% 증가' },
				{ img: require('@/assets/images/web/816/other/skill_203.png'), desc: '연심:1레벨마다 HP 750 증가' },
				{ img: require('@/assets/images/web/816/other/skill_204.png'), desc: '백미:1레벌마다 물리 방어력 50 증가' },
				{ img: require('@/assets/images/web/816/other/skill_205.png'), desc: '경국:1레벌마다 마법 방어력 50 증가' }
			],
			skillImg: require('@/assets/images/web/816/other/role2.gif'),
			roleImg: require('@/assets/images/web/816/other/role2.png')
		},
		{
			skillTitle: '궁국기:봉화적벽',
			skillDesc: '적군 전체 인원에게 공격력 150%의 피해를 입힘',
			skills: [
				{ img: require('@/assets/images/web/816/other/skill_301.png'), desc: '감로:1레벨마다 공격력 100 증가' },
				{ img: require('@/assets/images/web/816/other/skill_302.png'), desc: '화신:전투 진입후 전체 인원 명중율 4.5% 증가' },
				{ img: require('@/assets/images/web/816/other/skill_303.png'), desc: '렬염:1레벨마다 HP 750 증가' },
				{ img: require('@/assets/images/web/816/other/skill_304.png'), desc: '욕화:1레벌마다 물리 방어력 50 증가' },
				{ img: require('@/assets/images/web/816/other/skill_305.png'), desc: '영자:1레벌마다 마법 방어력 50 증가' }
			],
			skillImg: require('@/assets/images/web/816/other/role3.gif'),
			roleImg: require('@/assets/images/web/816/other/role3.png')
		},
		{
			skillTitle: '궁국기:백룡음',
			skillDesc: '목표 적군에게 공격력 280% 피해를 입히고 2초간 기절 효과를 조성,지속시간 2초',
			skills: [
				{ img: require('@/assets/images/web/816/other/skill_401.png'), desc: '분노포효:1레벨마다 공격력 80 증가' },
				{ img: require('@/assets/images/web/816/other/skill_402.png'), desc: '등잔:전투 진입후 전체 인원 회피율 3% 증가' },
				{ img: require('@/assets/images/web/816/other/skill_403.png'), desc: '용양:1레벨마다 HP 600 증가' },
				{ img: require('@/assets/images/web/816/other/skill_404.png'), desc: '용담:1레벌마다 물리 방어력 40 증가' },
				{ img: require('@/assets/images/web/816/other/skill_405.png'), desc: '칠진칠출:1레벌마다 마법 방어력 40 증가' }
			],
			skillImg: require('@/assets/images/web/816/other/role4.gif'),
			roleImg: require('@/assets/images/web/816/other/role4.png')
		},
		{
			skillTitle: '궁국기:낙신부',
			skillDesc: '현재 적군의 단일 유닛에게 공격력 280%의 피해를 입힘',
			skills: [
				{ img: require('@/assets/images/web/816/other/skill_501.png'), desc: '감로:1레벨마다 공격력 64 증가' },
				{ img: require('@/assets/images/web/816/other/skill_502.png'), desc: '영혼곡:전투 진입후 전체 인원 데미지 1% 증가' },
				{ img: require('@/assets/images/web/816/other/skill_503.png'), desc: '단장곡:1레벨마다 HP 480 증가' },
				{ img: require('@/assets/images/web/816/other/skill_504.png'), desc: '파진곡:1레벌마다 물리 방어력 32 증가' },
				{ img: require('@/assets/images/web/816/other/skill_505.png'), desc: '낙신:1레벌마다 마법 방어력 32 증가' }
			],
			skillImg: require('@/assets/images/web/816/other/role5.gif'),
			roleImg: require('@/assets/images/web/816/other/role5.png')
		},
	])
	const handleRole = (val) => {
		activeRole.value = val
	}
</script>

<style lang="less" scoped>
	section{
		min-width: 1280px;
	}
	.roleBox{
		width:100%;height: calc(100vw * 664 / 1920 - 4px);min-height: 438px;
		// background: url('~@/assets/images/bg2.jpg') no-repeat center;
		background-repeat: no-repeat;background-position: center;
		background-size: 100%;
		.roleDetail{
			width: 62.4%;margin: 0 auto;
			height: 87%;position: relative;padding: 5% 5% 2%;
			background: url('~@/assets/images/web/816/other/role-bg.png') no-repeat center bottom;
			background-size: 100%;
			.avaBox{
				width: 420px;position: absolute;left: 50%;top: 18%;
				transform: translate(-50%,0);
				ul{
					display: flex;align-items: center;justify-content: space-around;
					li{
						width: 68px;height: 68px;-webkit-filter: grayscale(100%);filter: grayscale(100%);
						background: url('~@/assets/images/web/816/other/role-nav-h.png') no-repeat;background-size: auto 100%;
						transition: 0.3s;cursor: pointer;
						&.active{
							-webkit-filter: grayscale(0%);filter: grayscale(0%);
						}
						&.role1{
							background-position: -1px 1px;
						}
						&.role2{
							background-position: -78px 1px;
						}
						&.role3{
							background-position: -157px 0;
						}
						&.role4{
							background-position: -235px 0;
						}
						&.role5{
							background-position: -311px 1px;
						}
					}
				}
			}
			.roleInfo{
				display: flex;align-items: center;justify-content: space-between;
				height: 100%;color: #5c432d;padding-top: 10%;padding-bottom: 3%;box-sizing: border-box;
				.bigSkill{
					text-align: center;width: 25%;height: 100%;padding: 3% 2% 0;
					box-sizing: border-box;
					h4{
						border: 1px solid #5c432d;border-radius: 15px;
						text-align: center;font-size: 0.875rem;font-weight: 500;
						display: inline-flex;padding: 0.125rem 0.75rem;
					}
					p{
						text-align: left;margin-top: 0.625rem;font-size: 0.75rem;
					}
					img{
						width: 100%;
					}
				}
				.skillList{
					width: 30%;padding-left: 16px;box-sizing: border-box;padding-top: 16px;
					h4{
						font-size: 0.875rem;margin-bottom: 10px;
					}
					ul{
						li{
							display: flex;align-items: center;padding: 5px 0;
							span{
								margin-left: 5px;font-size: 0.75rem;
							}
						}
					}
				}
				.rolePerson{
					width: 45%;text-align: right;height: 100%;padding: 2% 2% 2% 0;
					box-sizing: border-box;
					img{
						max-width: 100%;max-height: 100%;
					}
				}
			}
		}
	}
</style>